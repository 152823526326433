<template>
  <div class="statistics-monitor-body-container">
    <div class="search-box">
      <Row>
        <Col span="24">
          <h2 class="search-title border-bottom">按条件搜索</h2>
        </Col>
      </Row>
      <Form class="search-form" :label-width="85" ref="formValidate" :model="searchForm" :rules="ruleValidate">
        <Row>
          <Col span="5">
            <Form-item label="会员姓名：">
              <Input v-model="searchForm.member_name" placeholder="请输入..."></Input>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="5">
            <Form-item label="性别：">
              <Select clearable v-model="searchForm.sex" placeholder="请选择">
                <Option :value="v.id" v-for="v in sexList" :key="v.id">{{v.name}}</Option>
              </Select>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="5">
            <Form-item label="采集方式：">
              <Select clearable v-model="searchForm.type" placeholder="请选择">
                <Option :value="v.id" v-for="v in typeList" :key="v.id">{{v.name}}</Option>
              </Select>
            </Form-item>
          </Col>
        </Row>
        <Row>
          <Col span="8">
            <Form-item label="上传时间：">
              <Date-picker @on-change="changeTime" type="daterange" placeholder="选择日期"></Date-picker>
            </Form-item>
          </Col>
        </Row>
        <Row>
          <Col span="8">
            <Form-item label="体重：">
              <Row>
                <Col span="10">
                  <Form-item prop="weight_start">
                    <Input v-model="searchForm.weight_start" placeholder="请输入..."></Input>
                  </Form-item>
                </Col>
                <Col span="2" class="tac">至</Col>
                <Col span="10">
                  <Form-item prop="weight_end">
                    <Input v-model="searchForm.weight_end" placeholder="请输入..."></Input>
                  </Form-item>
                </Col>
                <Col span="2" class="tac">kg</Col>
              </Row>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="7">
            <Form-item label="体格指数：">
              <Row>
                <Col span="10">
                  <Form-item prop="build_start">
                    <Input v-model="searchForm.build_start" placeholder="请输入..."></Input>
                  </Form-item>
                </Col>
                <Col span="2" class="tac">至</Col>
                <Col span="10">
                  <Form-item prop="build_end">
                    <Input v-model="searchForm.build_end" placeholder="请输入..."></Input>
                  </Form-item>
                </Col>
                <Col span="2" class="tac"></Col>
              </Row>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="7">
            <Form-item label="体脂肪率：">
              <Row>
                <Col span="10">
                  <Form-item prop="fat_start">
                    <Input v-model="searchForm.fat_start" placeholder="请输入..."></Input>
                  </Form-item>
                </Col>
                <Col span="2" class="tac">至</Col>
                <Col span="10">
                  <Form-item prop="fat_end">
                    <Input v-model="searchForm.fat_end" placeholder="请输入..."></Input>
                  </Form-item>
                </Col>
                <Col span="2" class="tac">%</Col>
              </Row>
            </Form-item>
          </Col>
        </Row>
        <Row>
          <Col span="8">
            <Form-item label="内脂指数：">
              <Row>
                <Col span="10">
                  <Form-item prop="lactone_start">
                    <Input v-model="searchForm.lactone_start" placeholder="请输入..."></Input>
                  </Form-item>
                </Col>
                <Col span="2" class="tac">至</Col>
                <Col span="10">
                  <Form-item prop="lactone_end">
                    <Input v-model="searchForm.lactone_end" placeholder="请输入..."></Input>
                  </Form-item>
                </Col>
                <Col span="2" class="tac"></Col>
              </Row>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="7">
            <Form-item label="骨矿含量：">
              <Row>
                <Col span="10">
                  <Form-item prop="bmc_start">
                    <Input v-model="searchForm.bmc_start" placeholder="请输入..."></Input>
                  </Form-item>
                </Col>
                <Col span="2" class="tac">至</Col>
                <Col span="10">
                  <Form-item prop="bmc_end">
                    <Input v-model="searchForm.bmc_end" placeholder="请输入..."></Input>
                  </Form-item>
                </Col>
                <Col span="2" class="tac">kg</Col>
              </Row>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="7">
            <Form-item label="体水分率：">
              <Row>
                <Col span="10">
                  <Form-item prop="water_start">
                    <Input v-model="searchForm.water_start" placeholder="请输入..."></Input>
                  </Form-item>
                </Col>
                <Col span="2" class="tac">至</Col>
                <Col span="10">
                  <Form-item prop="water_end">
                    <Input v-model="searchForm.water_end" placeholder="请输入..."></Input>
                  </Form-item>
                </Col>
                <Col span="2" class="tac">%</Col>
              </Row>
            </Form-item>
          </Col>
        </Row>
        <Row>
          <Col span="8">
            <Form-item label="骨骼肌率：">
              <Row>
                <Col span="10">
                  <Form-item prop="musculi_start">
                    <Input v-model="searchForm.musculi_start" placeholder="请输入..."></Input>
                  </Form-item>
                </Col>
                <Col span="2" class="tac">至</Col>
                <Col span="10">
                  <Form-item prop="musculi_end">
                    <Input v-model="searchForm.musculi_end" placeholder="请输入..."></Input>
                  </Form-item>
                </Col>
                <Col span="2" class="tac">%</Col>
              </Row>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="7">
            <Form-item label="基础代谢：">
              <Row>
                <Col span="10">
                  <Form-item prop="basic_start">
                    <Input v-model="searchForm.basic_start" placeholder="请输入..."></Input>
                  </Form-item>
                </Col>
                <Col span="2" class="tac">至</Col>
                <Col span="10">
                  <Form-item prop="basic_end">
                    <Input v-model="searchForm.basic_end" placeholder="请输入..."></Input>
                  </Form-item>
                </Col>
                <Col span="2" class="tac">kcal</Col>
              </Row>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="7">
            <Form-item label="年龄：">
              <Row>
                <Col span="10">
                  <Form-item prop="age_start">
                    <Input v-model="searchForm.age_start" placeholder="请输入..."></Input>
                  </Form-item>
                </Col>
                <Col span="2" class="tac">至</Col>
                <Col span="10">
                  <Form-item prop="age_end">
                    <Input v-model="searchForm.age_end" placeholder="请输入..."></Input>
                  </Form-item>
                </Col>
                <Col span="2" class="tac">岁</Col>
              </Row>
            </Form-item>
          </Col>
        </Row>
        <Row>
          <Col span="7">
            <Form-item label="省份：">
              <Input disabled v-if="this.power.indexOf('数据统计--查询全国数据的权限') === -1 && this.power.indexOf('数据统计--查询区域数据的权限') === -1" v-model="pro_name"></Input>
              <Select clearable v-model="searchForm.pid" placeholder="请选择" @on-change="changePro" v-else>
                <Option :value="v.id" v-for="v in proList" :key="v.id">{{v.name}}</Option>
              </Select>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="7">
            <Form-item label="城市：">
              <Input disabled v-if="this.power.indexOf('数据统计--查询全国数据的权限') === -1 && this.power.indexOf('数据统计--查询区域数据的权限') === -1" v-model="city_name"></Input>
              <Select clearable v-model="searchForm.cid" placeholder="请选择" @on-change="changeCity" v-else>
                <Option :value="v.id" v-for="v in cityList" :key="v.id">{{v.name}}</Option>
              </Select>
            </Form-item>
          </Col>
          <Col span="1">&nbsp;</Col>
          <Col span="7">
            <Form-item label="门店：">
              <Input disabled v-if="this.power.indexOf('数据统计--查询全国数据的权限') === -1 && this.power.indexOf('数据统计--查询区域数据的权限') === -1" v-model="branch_name"></Input>
              <Select clearable v-model="searchForm.sid" placeholder="请选择" v-else>
                <Option :value="v.id" v-for="v in storeList" :key="v.id">{{v.name}}</Option>
              </Select>
            </Form-item>
          </Col>
        </Row>
        <div class="tac">
          <Button type="primary" class="search-btn" @click="handleSubmit('formValidate')">搜索</Button>
        </div>
      </Form>
    </div>
    <div class="export-div clearfix">
      <a href="javascript:;" class="export-btn" @click="exportData()" v-if="this.power.indexOf('数据统计--数据导出权限') > -1">导出数据</a>
    </div>
    <Table stripe :columns="columns" :data="bodyData" @on-selection-change="selectOne" ref="table"></Table>
    <Row>
      <Col span="12">
        <div class="table-oprate">
          <!--<Button @click="">删除</Button>-->
        </div>
      </Col>
      <Col span="12">
        <span class="records">共{{pageTotal}}条记录</span>
      </Col>
    </Row>
    <div class="page-nations-wrapper">
      <div class="page-nations">
        <Page :total="pageTotal" :current="searchForm.page" :page-size="searchForm.size" @on-change="changePage"></Page>
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import warningService from '@/services/warningService';
import statisticsService from '@/services/statisticsService';
export default {
	data() {
		const weightSmall = (rule, value, callback) => {
			if (value === '') {
				if (this.searchForm.weight_end !== '') {
					this.$refs.formValidate.validateField('weight_end');
				}
				callback();
			} else {
				if (Number.isNaN(Number(value))) {
					callback(new Error('请输入数字值'));
				} else {
					if (this.searchForm.weight_end !== '') {
						this.$refs.formValidate.validateField('weight_end');
					}
					callback();
				}
			}
		};
		const weightLarge = (rule, value, callback) => {
			if (value === '') {
				callback();
			} else {
				if (Number.isNaN(Number(value))) {
					callback(new Error('请输入数字值'));
				} else {
					if (
						this.searchForm.weight_start !== '' &&
						Number(value) < Number(this.searchForm.weight_start)
					) {
						callback(new Error('请输入更大数值'));
					}
					callback();
				}
			}
		};
		const buildSmall = (rule, value, callback) => {
			if (value === '') {
				if (this.searchForm.build_end !== '') {
					this.$refs.formValidate.validateField('build_end');
				}
				callback();
			} else {
				if (Number.isNaN(Number(value))) {
					callback(new Error('请输入数字值'));
				} else {
					if (this.searchForm.build_end !== '') {
						this.$refs.formValidate.validateField('build_end');
					}
					callback();
				}
			}
		};
		const buildLarge = (rule, value, callback) => {
			if (value === '') {
				callback();
			} else {
				if (Number.isNaN(Number(value))) {
					callback(new Error('请输入数字值'));
				} else {
					if (
						this.searchForm.build_start !== '' &&
						Number(value) < Number(this.searchForm.build_start)
					) {
						callback(new Error('请输入更大数值'));
					}
					callback();
				}
			}
		};
		const fatSmall = (rule, value, callback) => {
			if (value === '') {
				if (this.searchForm.fat_end !== '') {
					this.$refs.formValidate.validateField('fat_end');
				}
				callback();
			} else {
				if (Number.isNaN(Number(value))) {
					callback(new Error('请输入数字值'));
				} else {
					if (this.searchForm.fat_end !== '') {
						this.$refs.formValidate.validateField('fat_end');
					}
					callback();
				}
			}
		};
		const fatLarge = (rule, value, callback) => {
			if (value === '') {
				callback();
			} else {
				if (Number.isNaN(Number(value))) {
					callback(new Error('请输入数字值'));
				} else {
					if (
						this.searchForm.fat_start !== '' &&
						Number(value) < Number(this.searchForm.fat_start)
					) {
						callback(new Error('请输入更大数值'));
					}
					callback();
				}
			}
		};
		const lactoneSmall = (rule, value, callback) => {
			if (value === '') {
				if (this.searchForm.lactone_end !== '') {
					this.$refs.formValidate.validateField('lactone_end');
				}
				callback();
			} else {
				if (Number.isNaN(Number(value))) {
					callback(new Error('请输入数字值'));
				} else {
					if (this.searchForm.lactone_end !== '') {
						this.$refs.formValidate.validateField('lactone_end');
					}
					callback();
				}
			}
		};
		const lactoneLarge = (rule, value, callback) => {
			if (value === '') {
				callback();
			} else {
				if (Number.isNaN(Number(value))) {
					callback(new Error('请输入数字值'));
				} else {
					if (
						this.searchForm.lactone_start !== '' &&
						Number(value) < Number(this.searchForm.lactone_start)
					) {
						callback(new Error('请输入更大数值'));
					}
					callback();
				}
			}
		};
		const waterSmall = (rule, value, callback) => {
			if (value === '') {
				if (this.searchForm.water_end !== '') {
					this.$refs.formValidate.validateField('water_end');
				}
				callback();
			} else {
				if (Number.isNaN(Number(value))) {
					callback(new Error('请输入数字值'));
				} else {
					if (this.searchForm.water_end !== '') {
						this.$refs.formValidate.validateField('water_end');
					}
					callback();
				}
			}
		};
		const waterLarge = (rule, value, callback) => {
			if (value === '') {
				callback();
			} else {
				if (Number.isNaN(Number(value))) {
					callback(new Error('请输入数字值'));
				} else {
					if (
						this.searchForm.water_start !== '' &&
						Number(value) < Number(this.searchForm.water_start)
					) {
						callback(new Error('请输入更大数值'));
					}
					callback();
				}
			}
		};
		const musculiSmall = (rule, value, callback) => {
			if (value === '') {
				if (this.searchForm.musculi_end !== '') {
					this.$refs.formValidate.validateField('musculi_end');
				}
				callback();
			} else {
				if (Number.isNaN(Number(value))) {
					callback(new Error('请输入数字值'));
				} else {
					if (this.searchForm.musculi_end !== '') {
						this.$refs.formValidate.validateField('musculi_end');
					}
					callback();
				}
			}
		};
		const musculiLarge = (rule, value, callback) => {
			if (value === '') {
				callback();
			} else {
				if (Number.isNaN(Number(value))) {
					callback(new Error('请输入数字值'));
				} else {
					if (
						this.searchForm.musculi_start !== '' &&
						Number(value) < Number(this.searchForm.musculi_start)
					) {
						callback(new Error('请输入更大数值'));
					}
					callback();
				}
			}
		};
		const bmcSmall = (rule, value, callback) => {
			if (value === '') {
				if (this.searchForm.bmc_end !== '') {
					this.$refs.formValidate.validateField('bmc_end');
				}
				callback();
			} else {
				if (Number.isNaN(Number(value))) {
					callback(new Error('请输入数字值'));
				} else {
					if (this.searchForm.bmc_end !== '') {
						this.$refs.formValidate.validateField('bmc_end');
					}
					callback();
				}
			}
		};
		const bmcLarge = (rule, value, callback) => {
			if (value === '') {
				callback();
			} else {
				if (Number.isNaN(Number(value))) {
					callback(new Error('请输入数字值'));
				} else {
					if (
						this.searchForm.bmc_start !== '' &&
						Number(value) < Number(this.searchForm.bmc_start)
					) {
						callback(new Error('请输入更大数值'));
					}
					callback();
				}
			}
		};
		const basicSmall = (rule, value, callback) => {
			if (value === '') {
				if (this.searchForm.basic_end !== '') {
					this.$refs.formValidate.validateField('basic_end');
				}
				callback();
			} else {
				if (Number.isNaN(Number(value))) {
					callback(new Error('请输入数字值'));
				} else {
					if (this.searchForm.basic_end !== '') {
						this.$refs.formValidate.validateField('basic_end');
					}
					callback();
				}
			}
		};
		const basicLarge = (rule, value, callback) => {
			if (value === '') {
				callback();
			} else {
				if (Number.isNaN(Number(value))) {
					callback(new Error('请输入数字值'));
				} else {
					if (
						this.searchForm.basic_start !== '' &&
						Number(value) < Number(this.searchForm.basic_start)
					) {
						callback(new Error('请输入更大数值'));
					}
					callback();
				}
			}
		};
		const ageSmall = (rule, value, callback) => {
			if (value === '') {
				if (this.searchForm.age_end !== '') {
					this.$refs.formValidate.validateField('age_end');
				}
				callback();
			} else {
				if (Number.isNaN(Number(value))) {
					callback(new Error('请输入数字值'));
				} else {
					if (this.searchForm.age_end !== '') {
						this.$refs.formValidate.validateField('age_end');
					}
					callback();
				}
			}
		};
		const ageLarge = (rule, value, callback) => {
			if (value === '') {
				callback();
			} else {
				if (Number.isNaN(Number(value))) {
					callback(new Error('请输入数字值'));
				} else {
					if (
						this.searchForm.age_start !== '' &&
						Number(value) < Number(this.searchForm.age_start)
					) {
						callback(new Error('请输入更大数值'));
					}
					callback();
				}
			}
		};
		return {
			searchForm: {
				page: 1,
				size: 10,
				member_name: '',
				sex: '',
				pid: '',
				cid: '',
				sid: '',
				type: '',
				start: '',
				end: '',
				weight_start: '',
				weight_end: '',
				build_start: '',
				build_end: '',
				fat_start: '',
				fat_end: '',
				lactone_start: '',
				lactone_end: '',
				water_start: '',
				water_end: '',
				musculi_start: '',
				musculi_end: '',
				bmc_start: '',
				bmc_end: '',
				basic_start: '',
				basic_end: '',
				age_start: '',
				age_end: '',
			},
			exportForm: {
				member_name: '',
				sex: '',
				pid: '',
				cid: '',
				sid: '',
				type: '',
				start: '',
				end: '',
				weight_start: '',
				weight_end: '',
				build_start: '',
				build_end: '',
				fat_start: '',
				fat_end: '',
				lactone_start: '',
				lactone_end: '',
				water_start: '',
				water_end: '',
				musculi_start: '',
				musculi_end: '',
				bmc_start: '',
				bmc_end: '',
				basic_start: '',
				basic_end: '',
				age_start: '',
				age_end: '',
			},
			sexList: [
				{
					id: 0,
					name: '男',
				},
				{
					id: 1,
					name: '女',
				},
			],
			typeList: [
				{
					id: 0,
					name: '手动',
				},
				{
					id: 1,
					name: 'app',
				},
				{
					id: 4,
					name: 'inbody',
				},
			],
			proList: [],
			cityList: [],
			city: {},
			storeList: [],
			store: {},
			columns: [
				{
					title: '所属分院',
					key: 'branch_name',
					align: 'center',
					width: 150,
					fixed: 'left',
				},
				{
					title: '会员姓名',
					key: 'member_name',
					align: 'center',
					fixed: 'left',
					width: 150,
					render: (h, params) => {
						return h('div', [
							h(
								'a',
								{
									style: {
										color: '#ff8400',
									},
									attrs: {
										target: '_blank',
										href:
											'#/base/member/detail/' +
											this.bodyData[params.index].member_id +
											'/' +
											2 +
											'/' +
											1,
									},
								},
								params.row.member_name,
							),
						]);
					},
				},
				{
					title: '性别',
					key: 'sex',
					align: 'center',
					width: 80,
					render: (h, params) => {
						if (params.row.sex === 0) {
							return h('div', '男');
						} else if (params.row.sex === 1) {
							return h('div', '女');
						} else {
							return h('div', params.row.sex);
						}
					},
				},
				{
					title: '年龄',
					key: 'age',
					align: 'center',
					width: 80,
					render: (h, params) => {
						if (params.row.age === null) {
							return h('div', params.row.age);
						} else {
							return h('div', params.row.age + '岁');
						}
					},
				},
				{
					title: '采集方式',
					key: 'type',
					align: 'center',
					width: 100,
					render: (h, params) => {
						if (params.row.type === '0') {
							return h('div', '手动');
						} else if (params.row.type === '1') {
							return h('div', 'app');
						} else if (params.row.type === '4') {
							return h('div', 'inbody');
						} else {
							return h('div', params.row.type);
						}
					},
				},
				{
					title: '体重',
					key: 'weight',
					align: 'center',
					width: 100,
					render: (h, params) => {
						if (params.row.weight === null || params.row.weight === 0) {
							return h('div', '');
						} else {
							return h('div', params.row.weight + 'kg');
						}
					},
				},
				{
					title: '体格指数',
					key: 'build',
					align: 'center',
					width: 100,
				},
				{
					title: '体脂肪率',
					key: 'body_fat',
					align: 'center',
					width: 100,
					render: (h, params) => {
						if (params.row.body_fat === null || params.row.body_fat === 0) {
							return h('div', '');
						} else {
							return h('div', params.row.body_fat + '%');
						}
					},
				},
				{
					title: '内脂指数',
					key: 'lactone',
					align: 'center',
					width: 100,
					render: (h, params) => {
						if (params.row.lactone === 0) {
							return h('div', '');
						} else {
							return h('div', params.row.lactone);
						}
					},
				},
				{
					title: '体水分率',
					key: 'body_water',
					align: 'center',
					width: 100,
					render: (h, params) => {
						if (params.row.body_water === null || params.row.body_water === 0) {
							return h('div', '');
						} else {
							return h('div', params.row.body_water + '%');
						}
					},
				},
				{
					title: '骨骼肌率',
					key: 'musculi_skeleti',
					align: 'center',
					width: 100,
					render: (h, params) => {
						if (params.row.musculi_skeleti === null || params.row.musculi_skeleti === 0) {
							return h('div', '');
						} else {
							return h('div', params.row.musculi_skeleti + '%');
						}
					},
				},
				{
					title: '骨矿含量',
					key: 'bmc',
					align: 'center',
					width: 100,
					render: (h, params) => {
						if (params.row.bmc === null || params.row.bmc === 0) {
							return h('div', '');
						} else {
							return h('div', params.row.bmc + 'kg');
						}
					},
				},
				{
					title: '基础代谢',
					key: 'basic',
					align: 'center',
					width: 100,
					render: (h, params) => {
						if (params.row.basic === null || params.row.basic === 0) {
							return h('div', '');
						} else {
							return h('div', params.row.basic + 'kcal');
						}
					},
				},
				{
					title: '脂肪体重',
					key: 'fm',
					align: 'center',
					width: 150,
					render: (h, params) => {
						if (params.row.fm === null || params.row.fm === 0) {
							return h('div', '');
						} else {
							return h('div', params.row.fm + 'Kg');
						}
					},
				},
				{
					title: '去脂体重',
					key: 'ffm',
					align: 'center',
					width: 100,
					render: (h, params) => {
						if (params.row.ffm === null || params.row.ffm === 0) {
							return h('div', '');
						} else {
							return h('div', params.row.ffm + 'kg');
						}
					},
				},
				{
					title: '腰臀脂肪比例',
					key: 'ytzfbl',
					align: 'center',
					width: 150,
					render: (h, params) => {
						if (params.row.ytzfbl === null || params.row.ytzfbl === 0) {
							return h('div', '');
						} else {
							return h('div', params.row.ytzfbl);
						}
					},
				},
				{
					title: '蛋白质',
					key: 'dbz',
					align: 'center',
					width: 100,
					render: (h, params) => {
						if (params.row.dbz === null || params.row.dbz === 0) {
							return h('div', '');
						} else {
							return h('div', params.row.dbz);
						}
					},
				},
				{
					title: '肌少症指数',
					key: 'asmi',
					align: 'center',
					width: 150,
					render: (h, params) => {
						if (params.row.asmi === null || params.row.asmi === 0) {
							return h('div', '');
						} else {
							return h('div', params.row.asmi);
						}
					},
				},
				{
					title: '生理年龄',
					key: 'bodyage',
					align: 'center',
					width: 100,
					render: (h, params) => {
						if (params.row.bodyage === null || params.row.bodyage === 0) {
							return h('div', '');
						} else {
							return h('div', params.row.bodyage);
						}
					},
				},
				{
					title: '九种体型判断',
					key: 'txpd',
					align: 'center',
					width: 150,
					render: (h, params) => {
						if (params.row.txpd === null || params.row.txpd === 0) {
							return h('div', '');
						} else {
							return h('div', params.row.txpd);
						}
					},
				},
				{ title: '上传时间', key: 'create_time', align: 'center', width: 180 },
			],
			bodyData: [],
			pageTotal: 0,
			ruleValidate: {
				weight_start: [{ validator: weightSmall, trigger: 'blur' }],
				weight_end: [{ validator: weightLarge, trigger: 'blur' }],
				build_start: [{ validator: buildSmall, trigger: 'blur' }],
				build_end: [{ validator: buildLarge, trigger: 'blur' }],
				fat_start: [{ validator: fatSmall, trigger: 'blur' }],
				fat_end: [{ validator: fatLarge, trigger: 'blur' }],
				lactone_start: [{ validator: lactoneSmall, trigger: 'blur' }],
				lactone_end: [{ validator: lactoneLarge, trigger: 'blur' }],
				water_start: [{ validator: waterSmall, trigger: 'blur' }],
				water_end: [{ validator: waterLarge, trigger: 'blur' }],
				musculi_start: [{ validator: musculiSmall, trigger: 'blur' }],
				musculi_end: [{ validator: musculiLarge, trigger: 'blur' }],
				bmc_start: [{ validator: bmcSmall, trigger: 'blur' }],
				bmc_end: [{ validator: bmcLarge, trigger: 'blur' }],
				basic_start: [{ validator: basicSmall, trigger: 'blur' }],
				basic_end: [{ validator: basicLarge, trigger: 'blur' }],
				age_start: [{ validator: ageSmall, trigger: 'blur' }],
				age_end: [{ validator: ageLarge, trigger: 'blur' }],
			},
			power: '',
			pro_name: '',
			city_name: '',
			branch_name: '',
		};
	},
	created() {
		this.power = localStorage.getItem('userPower');
		this.pro_name = JSON.parse(localStorage.getItem('userInfo')).user_info.pro_name;
		this.city_name = JSON.parse(localStorage.getItem('userInfo')).user_info.city_name;
		this.branch_name = JSON.parse(localStorage.getItem('userInfo')).user_info.branch_name;
		if (
			this.power.indexOf('数据统计--查询全国数据的权限') === -1 &&
			this.power.indexOf('数据统计--查询区域数据的权限') === -1
		) {
			this.searchForm.pid = JSON.parse(localStorage.getItem('userInfo')).user_info.pid;
			this.searchForm.cid = JSON.parse(localStorage.getItem('userInfo')).user_info.cid;
			this.searchForm.sid = JSON.parse(localStorage.getItem('userInfo')).user_info.sid;
		}
		this.getBodyData();
		warningService.getWarningSelect().then((data) => {
			this.proList = data.branch.pro;
			this.city = data.branch.city;
			this.store = data.branch.store;
		});
	},
	methods: {
		changePro() {
			this.cityList = this.city[this.searchForm.pid];
			this.searchForm.cid = '';
		},
		changeCity() {
			this.storeList = this.store[this.searchForm.cid];
			this.searchForm.sid = '';
		},
		getBodyData() {
			statisticsService.getBodyData(this.searchForm).then((data) => {
				this.bodyData = data.list;
				this.pageTotal = data.row_size;
			});
		},
		handleSubmit(name) {
			this.$refs[name].validate((valid) => {
				if (valid) {
					this.searchForm.page = 1;
					this.getBodyData();
					this.exportForm.member_name = this.searchForm.member_name;
					this.exportForm.sex = this.searchForm.sex;
					this.exportForm.pid = this.searchForm.pid;
					this.exportForm.cid = this.searchForm.cid;
					this.exportForm.sid = this.searchForm.sid;
					this.exportForm.start = this.searchForm.start;
					this.exportForm.end = this.searchForm.end || '';
					this.exportForm.type = this.searchForm.type;
					this.exportForm.weight_start = this.searchForm.weight_start;
					this.exportForm.weight_end = this.searchForm.weight_end;
					this.exportForm.build_start = this.searchForm.build_start;
					this.exportForm.build_end = this.searchForm.build_end;
					this.exportForm.fat_start = this.searchForm.fat_start;
					this.exportForm.fat_end = this.searchForm.fat_end;
					this.exportForm.lactone_start = this.searchForm.lactone_start;
					this.exportForm.lactone_end = this.searchForm.lactone_end;
					this.exportForm.water_start = this.searchForm.water_start;
					this.exportForm.water_end = this.searchForm.water_end;
					this.exportForm.musculi_start = this.searchForm.musculi_start;
					this.exportForm.musculi_end = this.searchForm.musculi_end;
					this.exportForm.bmc_start = this.searchForm.bmc_start;
					this.exportForm.bmc_end = this.searchForm.bmc_end;
					this.exportForm.basic_start = this.searchForm.basic_start;
					this.exportForm.basic_end = this.searchForm.basic_end;
					this.exportForm.age_start = this.searchForm.age_start;
					this.exportForm.age_end = this.searchForm.age_end;
				}
			});
		},
		changeTime(date) {
			this.searchForm.start = date[0];
			this.searchForm.end = date[1];
		},
		changePage(page) {
			this.searchForm.page = page;
			this.getBodyData();
		},
		selectOne() {},
		exportData() {
			location.href =
				'http://conlife.sskh.net:9928/api/body/export?member_name=' +
				this.exportForm.member_name +
				'&sex=' +
				this.exportForm.sex +
				'&pid=' +
				this.exportForm.pid +
				'&cid=' +
				this.exportForm.cid +
				'&sid=' +
				this.exportForm.sid +
				'&start=' +
				this.exportForm.start +
				'&end=' +
				this.exportForm.end +
				'&type=' +
				this.exportForm.type +
				'&weight_start=' +
				this.exportForm.weight_start +
				'&weight_end=' +
				this.exportForm.weight_end +
				'&build_start=' +
				this.exportForm.build_start +
				'&build_end=' +
				this.exportForm.build_end +
				'&fat_start=' +
				this.exportForm.fat_start +
				'&fat_end=' +
				this.exportForm.fat_end +
				'&lactone_start=' +
				this.exportForm.lactone_start +
				'&lactone_end=' +
				this.exportForm.lactone_end +
				'&water_start=' +
				this.exportForm.water_start +
				'&water_end=' +
				this.exportForm.water_end +
				'&musculi_start=' +
				this.exportForm.musculi_start +
				'&musculi_end=' +
				this.exportForm.musculi_end +
				'&bmc_start=' +
				this.exportForm.bmc_start +
				'&bmc_end=' +
				this.exportForm.bmc_end +
				'&basic_start=' +
				this.exportForm.basic_start +
				'&basic_end=' +
				this.exportForm.basic_end +
				'&age_start=' +
				this.exportForm.age_start +
				'&age_end=' +
				this.exportForm.age_end;
		},
	},
};
</script>

<style lang="css" scoped>
.statistics-monitor-body-container .search-box {
  border: 1px solid #d7dde4;
}
</style>
